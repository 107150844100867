@import 'scss/base/variables';
@import 'scss/base/mixins';
@import 'scss/vendors/include-media';


.my-profile-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    @include animate(color);
    color: var(--primary-color);
  }

  .profile-info {
    font-size: 14px;
    line-height: 1.1;

    @include media('<tablet') {
      display: none;
    }

    .name {}

    .role {
      font-size: 12px;
      color: var(--stormGray);
      margin-bottom: 2px;
    }
  }


  .anticon-down {
    font-size: 10px;
    margin-left: 6px;
  }

  .profile-icon {
    font-size: 18px;
    margin-right: 2px;

    @include media('>=tablet') {
      font-size: 24px;
      margin-right: 7px;
    }
  }
}

.dropdown-profile {
  display: inline-flex;
  align-items: center;

  .user-logo-icon {
    font-size: 29px;
    opacity: .6;
    margin-right: 15px;
  }
}

.dropdown-profile-overlay {
  font-size: 12px;

  .ant-dropdown-menu {

    .ant-dropdown-menu-item {

      .ant-dropdown-menu-title-content {
        color: var(--charlestonGray);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}